<template>
  <div v-loading="loading">
    <component
      v-if="mappedField && mappedField.components"
      v-bind:is="mappedField.components.execute"
      :data="mappedField"
      :form="form"
      :fieldsData="fieldsData"
      :is-view="false"
      :hasLabel="false"
      :parentFormData="parentFormData"
      :autoFillEntityData="autoFillEntityData"
      :autoFillPrefeeredEntityData="autoFillPrefeeredEntityData"
      @applyFormRules="rulesEventEmitter"
    ></component>
  </div>
</template>

<script>
import { dataTableAllowedExcecuteFields } from "./index";
var fieldsList = require("../fields.json");

export default {
  data() {
    return {
      fieldActions: [],
      fieldComponentsMap: {},
      mappedField: {},
      loading: false,
    };
  },
  props: [
    "fieldsData",
    "form",
    "field",
    "parentFormData",
    "autoFillEntityData",
    "autoFillPrefeeredEntityData",
  ],
  components: {
    ...dataTableAllowedExcecuteFields,
  },
  mounted() {
    this.loading = true;
    if (fieldsList) {
      this.fieldActions = [
        ...fieldsList.allFormFields,
        ...fieldsList.allContentFields,
        ...fieldsList.allAdvancedFields,
      ];
    }
    if (this.fieldActions) {
      this.fieldActions.forEach((field) => {
        if (!this.fieldComponentsMap[field.inputType]) {
          this.fieldComponentsMap[field.inputType] = field.components;
        }
      });
    }
    this.mappedField = { ...this.field };
    if (!this.mappedField.components) {
      this.$set(
        this.mappedField,
        "components",
        this.fieldComponentsMap[this.field.input_type]
      );
    }
    if (this.mappedField?.properties?.filed_content == "Hide") {
      this.mappedField.properties.filed_content = "";
    }
    setTimeout(() => {
      this.loading = false;
    }, 100);
  },
  methods: {
    rulesEventEmitter() {
      this.$emit("applyFormRules");
    },
  },
};
</script>

<style></style>
